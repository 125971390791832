import '../sass/main.scss';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer>
      <ScrollAnimation animateIn="slide-up" animateOnce={true}>
        <section className="footer-container">
          <h4>Christine Lyston</h4>
          <Link to="mailto:calyston.14@outlook.com"><button className="cta">Get In Touch <FontAwesomeIcon icon={faEnvelope} /></button></Link>
          <div className="footer-links">
            <Link to="https://github.com/calyston" target="blank"><FontAwesomeIcon icon={faGithub} /></Link>
            <Link to="https://www.linkedin.com/in/christine-lyston/" target="blank"><FontAwesomeIcon icon={faLinkedin} /></Link>
            <Link to="https://www.instagram.com/calyston14/" target="blank"><FontAwesomeIcon icon={faInstagram} /></Link>
          </div>
          <p>© 2023 Christine Lyston</p>
        </section>
      </ScrollAnimation>
    </footer>
  )
}

export default Footer;