import '../sass/main.scss';
import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

const About = () => {
  return (
    <div className="about-section">
      <h2>About Christine</h2>
      <article className="about-information">
        <p>I'm an experienced UX/UI designer with a solid background in front-end web development. Over the past two years, I've been working as a freelance website developer, honing my skills and gaining valuable experience.</p>
        <p>I'm proficient in the popular design softwares Figma and Adobe XD, and I'm also well-versed in programming frameworks and content management systems such as React and WordPress.</p>
        <p>If you would like to know more, don't be afraid to drop me a line!</p>
      </article>

      <div className="about-button-links">
        <Link to="mailto:calyston.14@outlook.com"><button className="cta">Send Me An Email <FontAwesomeIcon icon={faEnvelope} /></button></Link>
        <Link to="https://github.com/calyston"><button className="cta">Visit my GitHub <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button></Link>
      </div>
    </div>
  )
}

export default About;