import '../sass/main.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import BackToTopButton from './BackToTopButton';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const Header = ({ toggleyOverflow }) => {
  return (
    <header>
      <nav>
        <section class="nav-container">
          <Link to="/"><h4>Christine Lyston</h4></Link>
          <MobileMenu toggleyOverflow={toggleyOverflow} />
          <div class="nav-links">
            <ul>
              <Link to="/"><li>Home</li></Link>
              <Link to="/about"><li>About</li></Link>
              <Link to="mailto:calyston.14@outlook.com"><li><button className="cta">Get In Touch <FontAwesomeIcon icon={faEnvelope} /></button></li></Link>
            </ul>
          </div>
        </section>
      </nav>
      <BackToTopButton />
    </header>
  )
}

export default Header;