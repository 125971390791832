// Back to Top Button

import '../sass/main.scss';
import React, { useState, useEffect } from 'react';
import useWindowScrollPosition from '@rehooks/window-scroll-position';

// Icons
import { BiArrowToTop } from 'react-icons/bi';

const BackToTopButton = () => {
  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  let { y } = useWindowScrollPosition();

  const [styles, setStyles] = useState({ display: "none" });

  useEffect(() => {
    if (y > 100) {
      setStyles({ display: "block", animation: "fadeIn 1s" })
    } else {
      setStyles({ display: "none" })
    }
  }, [y]);


  return (
    <div className="back-to-top-container">
      <button className="back-to-top" onClick={handleBackToTop} style={styles}>
        <BiArrowToTop />
      </button>
    </div>
  );
};

export default BackToTopButton;