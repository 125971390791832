import './sass/main.scss';
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

//Components
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutPage from './components/AboutPage';

//Pages

import CaseStudy1 from './components/CaseStudy1';
import CaseStudy2 from './components/CaseStudy2';
import CaseStudy3 from './components/CaseStudy3';
import UIProject1 from './components/UIProject1';

function App() {

  const [yOverflow, setyOverflow] = useState(true);
  const toggleyOverflow = () => {
    setyOverflow(!yOverflow);
  }

  // Change the Title of a Page

  // the pathname is what will trigger the title change
  const { pathname } = useLocation();

  //change the title of the document like this
  useEffect(() => {
    console.log("pathname:", pathname)
    if (pathname === "/") {
      document.title = "Home | Christine Lyston";
    }
    else if (pathname === "/about") {
      document.title = "About | Christine Lyston";
    }
    else if (pathname === "/case-study-1") {
      document.title = "Bandai Namco Store Case Study | Christine Lyston";
    }
    else if (pathname === "/case-study-2") {
      document.title = "Opodo Case Study | Christine Lyston";
    }
    else if (pathname === "/case-study-3") {
      document.title = "Abeno Case Study | Christine Lyston";
    }
    else if (pathname === "/ui-project-1") {
      document.title = "EyesOnMe Project | Christine Lyston";
    }

  }, [pathname]);

  return (
    <div className={yOverflow ? "" : "hideOverflow"}>
      <Header toggleyOverflow={toggleyOverflow} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/case-study-1" element={<CaseStudy1 />} />
        <Route path="/case-study-2" element={<CaseStudy2 />} />
        <Route path="/case-study-3" element={<CaseStudy3 />} />
        <Route path="/ui-project-1" element={<UIProject1 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
