// Abeno Case Study

import '../sass/main.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFigma } from '@fortawesome/free-brands-svg-icons';

// Content
import {
  CS3introText, CS3introImages,
  CS3competiorAnalysisText, CS3competitorAnalysisImages, CS3CAImagesHomepage, CS3CAImagesAboutPage, CS3CAImagesMenu,
  CS3userResearchImages,
  CS3ideationText, CS3ideationImages,
  CS3lowFidelityWireframes, CS3highFidelityWireframes,
  CS3uiPhaseImages, CS3conclusionText
} from './CaseStudy3Content';

const CaseStudy3 = () => {
  const [showMoreIntro, setShowMoreIntro] = useState(false);
  const [showMoreCA, setShowMoreCA] = useState(false);
  const [showMoreHighWireframes, setShowMoreHighWireframes] = useState(false);
  return (
    <section className="page-container project-page">
      <h1>Abeno</h1>

      <article className="project-details">
        <div>
          <h5>Client</h5>
          <p>Personal Project</p>
        </div>
        <div>
          <h5>Role</h5>
          <p>UX/UI Designer</p>
        </div>
        <div>
          <h5>Date</h5>
          <p>May 2023</p>
        </div>
        <div>
          <h5>Link</h5>
          <Link to="https://www.figma.com/proto/XKa03dRGqh0l9KSgQUJWDE/Case-Study-3?type=design&node-id=45-601&scaling=min-zoom&page-id=1%3A80&starting-point-node-id=45%3A601" target="blank"><FontAwesomeIcon icon={faFigma} /></Link>
        </div>
      </article>

      {/* Intro */}
      <article>
        <h2>Introduction</h2>
        <p>{CS3introText[0].text}</p>
        <p>{CS3introText[1].text}</p>

        <h3 className="centre-elements">Problems</h3>
        <p>{CS3introText[2].text}</p>
        <p>{CS3introText[3].text}</p>
        <p>{CS3introText[4].text}</p>
        <p>{CS3introText[5].text}</p>
        <p>{CS3introText[6].text}</p>
      </article>

      <article className="centre-elements">
        <h3>Website Screenshots</h3>
        <button onClick={() => setShowMoreIntro(!showMoreIntro)}>
          {showMoreIntro ? "Hide Images" : "Show Images"}
        </button>
        {showMoreIntro &&
          <div className="centre-elements">
            <div className="image-grid">
              <img className="small-image" src={CS3introImages[0].img} alt={CS3introImages[0].alt} />
              <img className="small-image" src={CS3introImages[1].img} alt={CS3introImages[1].alt} />
              <img className="medium-image" src={CS3introImages[2].img} alt={CS3introImages[2].alt} />
            </div>
            <button onClick={() => setShowMoreIntro(!showMoreIntro)}>
              {showMoreIntro ? "Hide Images" : "Show Images"}
            </button>
          </div>
        }
      </article>

      {/* Competitor Analysis */}
      <article >
        <h2>Competitor Analysis</h2>
        <p>{CS3competiorAnalysisText[0].text}</p>
        <p>{CS3competiorAnalysisText[1].text}</p>
        <p>{CS3competiorAnalysisText[2].text}</p>
        <div className="centre-elements">
          <img className="extra-large-image" src={CS3competitorAnalysisImages[4].img} alt={CS3competitorAnalysisImages[4].alt} />

          <button onClick={() => setShowMoreCA(!showMoreCA)}>
            {showMoreCA ? "Hide Images" : "Show Images"}
          </button>
          {showMoreCA &&
            <div className="centre-elements">
              <h3>Homepages</h3>
              <div className="ca-grid">
                <img className="ca-grid-image" src={CS3CAImagesHomepage[0].img} alt={CS3CAImagesHomepage[0].alt} />
                <img className="ca-grid-image" src={CS3CAImagesHomepage[1].img} alt={CS3CAImagesHomepage[1].alt} />
                <img className="ca-grid-image" src={CS3CAImagesHomepage[2].img} alt={CS3CAImagesHomepage[2].alt} />
                <img className="ca-grid-image" src={CS3CAImagesHomepage[3].img} alt={CS3CAImagesHomepage[3].alt} />
              </div>

              <h3>About Pages</h3>
              <div className="ca-grid">
                <img className="ca-grid-image" src={CS3CAImagesAboutPage[0].img} alt={CS3CAImagesAboutPage[0].alt} />
                <img className="ca-grid-image" src={CS3CAImagesAboutPage[1].img} alt={CS3CAImagesAboutPage[1].alt} />
                <img className="ca-grid-image" src={CS3CAImagesAboutPage[2].img} alt={CS3CAImagesAboutPage[2].alt} />
              </div>

              <h3>Menus</h3>
              <div className="ca-grid">
                <img className="ca-grid-image" src={CS3CAImagesMenu[0].img} alt={CS3CAImagesMenu[0].alt} />
                <img className="ca-grid-image" src={CS3CAImagesMenu[1].img} alt={CS3CAImagesMenu[1].alt} />
                <img className="ca-grid-image" src={CS3CAImagesMenu[2].img} alt={CS3CAImagesMenu[2].alt} />
                <img className="ca-grid-image" src={CS3CAImagesMenu[3].img} alt={CS3CAImagesMenu[3].alt} />
              </div>
              <button onClick={() => setShowMoreCA(!showMoreCA)}>
                {showMoreCA ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>
      </article>

      {/* User Research */}
      <article>
        <h2>User Research</h2>
        <img className="extra-large-image" src={CS3userResearchImages[0].img} alt={CS3userResearchImages[0].alt} />
      </article>

      {/* Ideation */}
      <article>
        <h2>Ideation</h2>
        <h3 className="centre-elements">Mind Map</h3>
        <div className="centre-elements">
          <p>{CS3ideationText[0].text}</p>
          <img className="medium-image" src={CS3ideationImages[0].img} alt={CS3ideationImages[0].alt} />
        </div>

      </article>

      {/* Wireframes */}
      <article>
        <h2>Wireframes</h2>

        {/* Low-Fidelity Wireframes */}
        <div className="centre-elements">
          <h3>Low-Fidelity Wireframes</h3>
          <img className="large-image" src={CS3lowFidelityWireframes[0].img} alt={CS3lowFidelityWireframes[0].alt} />
        </div>
        {/* High-Fidelity Wireframes */}
        <div className="centre-elements">
          <h3>High-Fidelity Wireframes</h3>
          <div className="image-grid">
            <div className="centre-elements">
              <h4>Before - Original Design</h4>
              <img className="small-image" src={CS3introImages[0].img} alt={CS3introImages[0].alt} />
            </div>
            <div className="centre-elements">
              <h4>After - My Design</h4>
              <img className="small-image" src={CS3highFidelityWireframes[0].img} alt={CS3highFidelityWireframes[0].alt} />
            </div>
          </div>
          <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
            {showMoreHighWireframes ? "Hide Images" : "Show Images"}
          </button>
          {showMoreHighWireframes &&
            <div className="centre-elements">
              <div className="image-grid">
                <img className="small-image" src={CS3highFidelityWireframes[1].img} alt={CS3highFidelityWireframes[1].img} />
                <img className="small-image" src={CS3highFidelityWireframes[2].img} alt={CS3highFidelityWireframes[2].img} />
              </div>
              <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
                {showMoreHighWireframes ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>
      </article>

      {/* UI Phase */}
      <article>
        <h2>UI Phase</h2>
        <div className="centre-elements">
          <img className="extra-large-image" src={CS3uiPhaseImages[0].img} alt={CS3uiPhaseImages[0].alt} />
          <img className="extra-large-image" src={CS3uiPhaseImages[1].img} alt={CS3uiPhaseImages[1].alt} />
          <img className="extra-large-image" src={CS3uiPhaseImages[2].img} alt={CS3uiPhaseImages[2].alt} />
          <img className="extra-large-image" src={CS3uiPhaseImages[3].img} alt={CS3uiPhaseImages[3].alt} />
        </div>
      </article>

      {/* Conclusion */}
      <article>
        <h2>Conclusion</h2>
        <p>{CS3conclusionText[0].text}</p>
        <p>{CS3conclusionText[1].text}</p>
        <p>{CS3conclusionText[2].text}</p>
        <p>{CS3conclusionText[3].text}</p>
      </article>
    </section>
  )
};

export default CaseStudy3;