// Bandai Namco Case Study

import '../sass/main.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFigma } from '@fortawesome/free-brands-svg-icons';

// Content
import {
  CS1introText, CS1introImages,
  CS1competiorAnalysisText, CS1competitorAnalysisImages,
  CS1CAImagesHomepage, CS1CAImagesProductsPage, CS1CAImagesSingleProduct,
  CS1userTestingText, CS1userTestingImages, CS1websiteImpressionsText, CS1tasksText,
  CS1cardSortText, CS1cardSortImages,
  CS1ideationText, CS1ideationImages,
  CS1lowFidelityWireframes, CS1midFidelityWireframes, CS1highFidelityWireframes,
  CS1uiPhaseImages,
  CS1conclusionText
} from './CaseStudy1Content';

const CaseStudy1 = () => {
  const [showMoreIntro, setShowMoreIntro] = useState(false);
  const [showMoreCA, setShowMoreCA] = useState(false);
  const [showMoreWebImpressions, setShowMoreWebImpressions] = useState(false);
  const [showMoreTasks, setShowMoreTasks] = useState(false);
  const [showMoreCardSort, setShowMoreCardSort] = useState(false);
  const [showMoreIdeation, setShowMoreIdeation] = useState(false);
  const [showMoreMidWireframes, setShowMoreMidWireframes] = useState(false);
  const [showMoreHighWireframes, setShowMoreHighWireframes] = useState(false);
  return (
    <section className="page-container project-page">
      <h1>Bandai Namco Store</h1>
      <article className="project-details">
        <div>
          <h5>Client</h5>
          <p>Love Circular</p>
        </div>
        <div>
          <h5>Role</h5>
          <p>UX/UI Designer</p>
        </div>
        <div>
          <h5>Date</h5>
          <p>March 2023</p>
        </div>
        <div>
          <h5>Link</h5>
          <Link to="https://www.figma.com/proto/3Oe5zrICx4KgY9wVAzI3fK/Case-Study-1?type=design&node-id=31-59&scaling=min-zoom&page-id=11%3A2&starting-point-node-id=31%3A59" target="blank"><FontAwesomeIcon icon={faFigma} /></Link>
        </div>
      </article>

      {/* Intro */}
      <article>
        <h2>Introduction</h2>
        <p>{CS1introText[0].text}</p>
        <p>{CS1introText[1].text}</p>
        <p>{CS1introText[2].text}</p>
        <p>{CS1introText[3].text}</p>
        <p>{CS1introText[4].text}</p>
      </article>

      <article className="centre-elements">
        <h3>Website Screenshots</h3>
        <button onClick={() => setShowMoreIntro(!showMoreIntro)}>
          {showMoreIntro ? "Hide Images" : "Show Images"}
        </button>
        {showMoreIntro &&
          <div className="centre-elements">
            <div className="image-grid">
              <img className="small-image" src={CS1introImages[0].img} alt={CS1introImages[0].alt} />
              <img className="mobile-image" src={CS1introImages[1].img} alt={CS1introImages[1].alt} />
            </div>
            <button onClick={() => setShowMoreIntro(!showMoreIntro)}>
              {showMoreIntro ? "Hide Images" : "Show Images"}
            </button>
          </div>
        }
      </article>

      {/* Competitor Analysis */}
      <article >
        <h2>Competitor Analysis</h2>
        <p>{CS1competiorAnalysisText[0].text}</p>
        <p>{CS1competiorAnalysisText[1].text}</p>
        <p>{CS1competiorAnalysisText[2].text}</p>
        <p>{CS1competiorAnalysisText[3].text}</p>
        <div className="centre-elements">
          <img className="extra-large-image" src={CS1competitorAnalysisImages[4].img} alt={CS1competitorAnalysisImages[4].alt} />

          <button onClick={() => setShowMoreCA(!showMoreCA)}>
            {showMoreCA ? "Hide Images" : "Show Images"}
          </button>
          {showMoreCA &&
            <div className="centre-elements">
              <h3>Homepages</h3>
              <div className="ca-grid">
                <img className="ca-grid-image" src={CS1CAImagesHomepage[0].img} alt={CS1CAImagesHomepage[0].alt} />
                <img className="ca-grid-image" src={CS1CAImagesHomepage[1].img} alt={CS1CAImagesHomepage[1].alt} />
                <img className="ca-grid-image" src={CS1CAImagesHomepage[2].img} alt={CS1CAImagesHomepage[2].alt} />
                <img className="ca-grid-image" src={CS1CAImagesHomepage[3].img} alt={CS1CAImagesHomepage[3].alt} />
              </div>

              <h3>Products Page</h3>
              <div className="ca-grid">
                <img className="ca-grid-image" src={CS1CAImagesProductsPage[0].img} alt={CS1CAImagesProductsPage[0].alt} />
                <img className="ca-grid-image" src={CS1CAImagesProductsPage[1].img} alt={CS1CAImagesProductsPage[1].alt} />
                <img className="ca-grid-image" src={CS1CAImagesProductsPage[2].img} alt={CS1CAImagesProductsPage[2].alt} />
                <img className="ca-grid-image" src={CS1CAImagesProductsPage[3].img} alt={CS1CAImagesProductsPage[3].alt} />
              </div>

              <h3>Single Product Page</h3>
              <div className="ca-grid">
                <img className="ca-grid-image" src={CS1CAImagesSingleProduct[0].img} alt={CS1CAImagesSingleProduct[0].alt} />
                <img className="ca-grid-image" src={CS1CAImagesSingleProduct[1].img} alt={CS1CAImagesSingleProduct[1].alt} />
                <img className="ca-grid-image" src={CS1CAImagesSingleProduct[2].img} alt={CS1CAImagesSingleProduct[2].alt} />
                <img className="ca-grid-image" src={CS1CAImagesSingleProduct[3].img} alt={CS1CAImagesSingleProduct[3].alt} />
              </div>
              <button onClick={() => setShowMoreCA(!showMoreCA)}>
                {showMoreCA ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>
      </article>

      {/* User Research */}
      <article>
        <h2>User Research</h2>
        <h3 className="centre-elements">User Testing</h3>
        <p>{CS1userTestingText[0].text}</p>
        <p>{CS1userTestingText[1].text}</p>

        <div className="centre-elements">
          <img className="large-image" src={CS1userTestingImages[0].img} alt={CS1userTestingImages[0].alt} />

          {/* Website Impressions */}
          <h3>{CS1websiteImpressionsText[0].text}</h3>
          <button onClick={() => setShowMoreWebImpressions(!showMoreWebImpressions)}>
            {showMoreWebImpressions ? "Read Less" : "Read More"}
          </button>
          {showMoreWebImpressions &&
            <div>
              <p>{CS1websiteImpressionsText[1].text}</p>
              <p>{CS1websiteImpressionsText[2].text}</p>
              <p>{CS1websiteImpressionsText[3].text}</p>
              <p>{CS1websiteImpressionsText[4].text}</p>
              <p>{CS1websiteImpressionsText[5].text}</p>

              <h4>{CS1websiteImpressionsText[6].text}:</h4>
              <p>{CS1websiteImpressionsText[7].text}</p>
              <p>{CS1websiteImpressionsText[8].text}</p>
              <p>{CS1websiteImpressionsText[9].text}</p>
              <p>{CS1websiteImpressionsText[10].text}</p>
              <p>{CS1websiteImpressionsText[11].text}</p>
              <p>{CS1websiteImpressionsText[12].text}</p>
              <div className="centre-elements">
                <button onClick={() => setShowMoreWebImpressions(!showMoreWebImpressions)}>
                  {showMoreWebImpressions ? "Read Less" : "Read More"}
                </button>
              </div>

            </div>}

          {/* Tasks */}
          <h3>{CS1tasksText[0].text}</h3>
          <button onClick={() => setShowMoreTasks(!showMoreTasks)}>
            {showMoreTasks ? "Read Less" : "Read More"}
          </button>
          {showMoreTasks &&
            <div>
              <h5>{CS1tasksText[1].text}</h5>
              <p>{CS1tasksText[2].text}</p>
              <p>{CS1tasksText[3].text}</p>
              <h5>{CS1tasksText[4].text}</h5>
              <p>{CS1tasksText[5].text}</p>
              <h5>{CS1tasksText[6].text}</h5>
              <p>{CS1tasksText[7].text}</p>
              <p>{CS1tasksText[8].text}</p>
              <p>{CS1tasksText[9].text}</p>
              <p>{CS1tasksText[10].text}</p>
              <h5>{CS1tasksText[11].text}</h5>
              <p>{CS1tasksText[12].text}</p>
              <p>{CS1tasksText[13].text}</p>
              <p>{CS1tasksText[14].text}</p>

              <div className="centre-elements">
                <button onClick={() => setShowMoreTasks(!showMoreTasks)}>
                  {showMoreTasks ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>}
        </div>
      </article>

      {/* Card Sorting */}
      <article>
        <h3 className="centre-elements">{CS1cardSortText[0].text}</h3>
        <p>{CS1cardSortText[1].text}</p>
        <p>{CS1cardSortText[2].text}</p>
        <div className="centre-elements">
          <img className="large-image" src={CS1cardSortImages[0].img} alt={CS1cardSortImages[0].alt} />
          <h3>Results</h3>
          <button onClick={() => setShowMoreCardSort(!showMoreCardSort)}>
            {showMoreCardSort ? "Read Less" : "Read More"}
          </button>
          {showMoreCardSort &&
            <div>
              <p>{CS1cardSortText[3].text}</p>
              <p>{CS1cardSortText[4].text}</p>
              <p>{CS1cardSortText[5].text}</p>
              <p>{CS1cardSortText[6].text}</p>
              <p>{CS1cardSortText[7].text}</p>
              <p>{CS1cardSortText[8].text}</p>
              <p>{CS1cardSortText[9].text}</p>
              <p>{CS1cardSortText[10].text}</p>
              <p>{CS1cardSortText[11].text}</p>
              <div className="centre-elements">
                <button onClick={() => setShowMoreCardSort(!showMoreCardSort)}>
                  {showMoreCardSort ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>}
        </div>
      </article>

      <article>
        <h2>Ideation</h2>
        <h3 className="centre-elements">{CS1ideationText[0].text}</h3>
        <p>{CS1ideationText[1].text}</p>

        <div className="centre-elements">
          <img className="medium-image" src={CS1ideationImages[0].img} alt={CS1ideationImages[0].alt} />
          <button onClick={() => setShowMoreIdeation(!showMoreIdeation)}>
            {showMoreIdeation ? "Read Less" : "Read More"}
          </button>
          {showMoreIdeation &&
            <div className="centre-elements">
              <p>{CS1ideationText[2].text}</p>
              <img className="small-image" src={CS1ideationImages[1].img} alt={CS1ideationImages[1].alt} />
              <p>{CS1ideationText[3].text}</p>
              <img className="small-image" src={CS1ideationImages[2].img} alt={CS1ideationImages[2].alt} />
              <p>{CS1ideationText[4].text}</p>
              <img className="small-image" src={CS1ideationImages[3].img} alt={CS1ideationImages[3].alt} />
              <p>{CS1ideationText[5].text}</p>
              <img className="medium-image" src={CS1ideationImages[4].img} alt={CS1ideationImages[4].alt} />
              <p>{CS1ideationText[6].text}</p>
              <div className="centre-elements">
                <button onClick={() => setShowMoreIdeation(!showMoreIdeation)}>
                  {showMoreIdeation ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>}
        </div>
      </article>

      <article>
        <h2>Wireframes</h2>
        <div className="centre-elements">
          <h3>Low-Fidelity Wireframes</h3>
          <img className="large-image" src={CS1lowFidelityWireframes[0].img} alt={CS1lowFidelityWireframes[0].alt} />
          <img className="large-image" src={CS1lowFidelityWireframes[1].img} alt={CS1lowFidelityWireframes[1].alt} />
        </div>

        <div className="centre-elements">
          <h3>Mid-Fidelity Wireframes</h3>
          <img className="small-image" src={CS1midFidelityWireframes[0].img} alt={CS1midFidelityWireframes[0].alt} />
          <button onClick={() => setShowMoreMidWireframes(!showMoreMidWireframes)}>
            {showMoreMidWireframes ? "Show Less" : "Show More"}
          </button>
          {showMoreMidWireframes &&
            <div>
              <div className="wireframe-grid">
                <img className="wireframe-grid-image" src={CS1midFidelityWireframes[1].img} alt={CS1midFidelityWireframes[1].alt} />
                <img className="wireframe-grid-image" src={CS1midFidelityWireframes[2].img} alt={CS1midFidelityWireframes[2].alt} />
                <img className="wireframe-grid-image" src={CS1midFidelityWireframes[3].img} alt={CS1midFidelityWireframes[3].alt} />
                <img className="mobile-image" src={CS1midFidelityWireframes[4].img} alt={CS1midFidelityWireframes[4].alt} />
                <img className="mobile-image" src={CS1midFidelityWireframes[5].img} alt={CS1midFidelityWireframes[5].alt} />
                <img className="mobile-image" src={CS1midFidelityWireframes[6].img} alt={CS1midFidelityWireframes[6].alt} />
                <img className="mobile-image" src={CS1midFidelityWireframes[7].img} alt={CS1midFidelityWireframes[7].alt} />
              </div>
              <div className="centre-elements">
                <button onClick={() => setShowMoreMidWireframes(!showMoreMidWireframes)}>
                  {showMoreMidWireframes ? "Show Less" : "Show More"}
                </button>
              </div>
            </div>
          }
        </div>

        <div className="centre-elements">
          <h3>High-Fidelity Wireframes</h3>
          <div className="image-grid">
            <div className="centre-elements">
              <h4>Before - Original Design</h4>
              <img className="small-image" src={CS1introImages[0].img} alt={CS1introImages[0].alt} />
            </div>
            <div className="centre-elements">
              <h4>After - My Design</h4>
              <img className="small-image" src={CS1highFidelityWireframes[0].img} alt={CS1highFidelityWireframes[0].alt} />
            </div>
          </div>

          <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
            {showMoreHighWireframes ? "Show Less" : "Show More"}
          </button>
          {showMoreHighWireframes &&
            <div>
              <div className="wireframe-grid">
                <img className="wireframe-grid-image" src={CS1highFidelityWireframes[1].img} alt={CS1highFidelityWireframes[1].alt} />
                <img className="wireframe-grid-image" src={CS1highFidelityWireframes[2].img} alt={CS1highFidelityWireframes[2].alt} />
                <img className="mobile-image" src={CS1highFidelityWireframes[3].img} alt={CS1highFidelityWireframes[3].alt} />
                <img className="mobile-image" src={CS1highFidelityWireframes[4].img} alt={CS1highFidelityWireframes[4].alt} />
                <img className="mobile-image" src={CS1highFidelityWireframes[5].img} alt={CS1highFidelityWireframes[5].alt} />
              </div>
              <div className="centre-elements">
                <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
                  {showMoreHighWireframes ? "Show Less" : "Show More"}
                </button>
              </div>
            </div>
          }
        </div>
      </article>

      <article>
        <h2>UI Phase</h2>
        <div className="centre-elements">
          <img className="extra-large-image" src={CS1uiPhaseImages[0].img} alt={CS1uiPhaseImages[0].alt} />
          <img className="extra-large-image" src={CS1uiPhaseImages[1].img} alt={CS1uiPhaseImages[1].alt} />
          <img className="extra-large-image" src={CS1uiPhaseImages[2].img} alt={CS1uiPhaseImages[2].alt} />
          <img className="extra-large-image" src={CS1uiPhaseImages[3].img} alt={CS1uiPhaseImages[3].alt} />
        </div>
      </article>

      <article>
        <h2>Conclusion</h2>
        <p>{CS1conclusionText[0].text}</p>
        <p>{CS1conclusionText[1].text}</p>
        <p>{CS1conclusionText[2].text}</p>
      </article>
    </section >
  );
};

export default CaseStudy1;