// EyesOnMe UI Project

import '../sass/main.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFigma } from '@fortawesome/free-brands-svg-icons';

// Content
import {
  UIP1introText,
  UIP1competiorAnalysisText, UIP1competitorAnalysisImages,
  UIP1lowFidelityWireframes, UIP1highFidelityWireframes,
  UIP1uiPhaseImages, UIP1conclusionText
} from './UIProject1Content';

const UIProject1 = () => {
  const [showMoreCA, setShowMoreCA] = useState(false);
  const [showMoreHighWireframes, setShowMoreHighWireframes] = useState(false);
  return (
    <section className="page-container project-page">
      <h1>EyesOnMe</h1>

      <article className="project-details">
        <div>
          <h5>Client</h5>
          <p>Personal Project</p>
        </div>
        <div>
          <h5>Role</h5>
          <p>UI Designer</p>
        </div>
        <div>
          <h5>Date</h5>
          <p>April 2023</p>
        </div>
        <div>
          <h5>Link</h5>
          <Link to="https://www.figma.com/proto/qWP4vpnYMaXkelc7aX2SlR/EyesOnMe?type=design&node-id=101-2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=101%3A2" target="blank"><FontAwesomeIcon icon={faFigma} /></Link>
        </div>
      </article>

      {/* Intro */}
      <article>
        <h2>Introduction</h2>
        <p>{UIP1introText[0].text}</p>
        <p>{UIP1introText[1].text}</p>
        <p>{UIP1introText[2].text}</p>
        <p>{UIP1introText[3].text}</p>
      </article>

      {/* Competitor Analysis */}
      <article >
        <h2>Competitor Analysis</h2>
        <p>{UIP1competiorAnalysisText[0].text}</p>

        <div className="centre-elements">
          <button onClick={() => setShowMoreCA(!showMoreCA)}>
            {showMoreCA ? "Hide Images" : "Show Images"}
          </button>
          {showMoreCA &&
            <div className="centre-elements">
              <div className="wireframe-grid">
                <img className="small-image" src={UIP1competitorAnalysisImages[0].img} alt={UIP1competitorAnalysisImages[0].alt} />
                <img className="small-image" src={UIP1competitorAnalysisImages[1].img} alt={UIP1competitorAnalysisImages[1].alt} />
                <img className="small-image" src={UIP1competitorAnalysisImages[2].img} alt={UIP1competitorAnalysisImages[2].alt} />
              </div>
              <button onClick={() => setShowMoreCA(!showMoreCA)}>
                {showMoreCA ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>
      </article>

      {/* Wireframes */}
      <article>
        <h2>Wireframes</h2>

        {/* Low-Fidelity Wireframes */}
        <div className="centre-elements">
          <h3>Low-Fidelity Wireframes</h3>
          <img className="large-image" src={UIP1lowFidelityWireframes[0].img} alt={UIP1lowFidelityWireframes[0].alt} />
        </div>

        {/* High-Fidelity Wireframes */}
        <div className="centre-elements">
          <h3>High-Fidelity Wireframes</h3>
          <div className="image-grid">
            <img className="mobile-image" src={UIP1highFidelityWireframes[0].img} alt={UIP1highFidelityWireframes[0].alt} />
            <img className="mobile-image" src={UIP1highFidelityWireframes[1].img} alt={UIP1highFidelityWireframes[1].img} />
          </div>
          <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
            {showMoreHighWireframes ? "Hide Images" : "Show Images"}
          </button>
          {showMoreHighWireframes &&
            <div className="centre-elements">
              <div className="ca-grid">
                <img className="mobile-image" src={UIP1highFidelityWireframes[2].img} alt={UIP1highFidelityWireframes[2].img} />
                <img className="mobile-image" src={UIP1highFidelityWireframes[3].img} alt={UIP1highFidelityWireframes[3].img} />
                <img className="mobile-image" src={UIP1highFidelityWireframes[4].img} alt={UIP1highFidelityWireframes[4].img} />
                <img className="mobile-image" src={UIP1highFidelityWireframes[5].img} alt={UIP1highFidelityWireframes[5].img} />
                <img className="mobile-image" src={UIP1highFidelityWireframes[6].img} alt={UIP1highFidelityWireframes[6].img} />
                <img className="mobile-image" src={UIP1highFidelityWireframes[7].img} alt={UIP1highFidelityWireframes[7].img} />
                <img className="mobile-image" src={UIP1highFidelityWireframes[8].img} alt={UIP1highFidelityWireframes[8].img} />
                <img className="mobile-image" src={UIP1highFidelityWireframes[9].img} alt={UIP1highFidelityWireframes[9].img} />
              </div>
              <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
                {showMoreHighWireframes ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>
      </article>

      {/* UI Phase */}
      <article>
        <h2>UI Phase</h2>
        <div className="centre-elements">
          <img className="extra-large-image" src={UIP1uiPhaseImages[0].img} alt={UIP1uiPhaseImages[0].alt} />
          <img className="extra-large-image" src={UIP1uiPhaseImages[1].img} alt={UIP1uiPhaseImages[1].alt} />
        </div>
      </article>

      {/* Conclusion */}
      <article>
        <h2>Conclusion</h2>
        <p>{UIP1conclusionText[0].text}</p>
        <p>{UIP1conclusionText[1].text}</p>
        <p>{UIP1conclusionText[2].text}</p>
      </article>

    </section>
  )
};

export default UIProject1;