// Bandai Namco Case Study

// Image Imports

import CS1screenshotDesktop from '../assets/case-study-1/bandai-namco-screenshot-desktop.png'
import CS1screenshotMobile from '../assets/case-study-1/bandai-namco-screenshot-mobile.png'

import CS1CompetitorAnalysis1 from '../assets/case-study-1/competitor-analysis-square-enix.png'
import CS1CompetitorAnalysis2 from '../assets/case-study-1/competitor-analysis-capcom.png'
import CS1CompetitorAnalysis3 from '../assets/case-study-1/competitor-analysis-sega.png'
import CS1CompetitorAnalysis4 from '../assets/case-study-1/competitor-analysis-game.png'
import CS1CompetitorAnalysisGrid from '../assets/case-study-1/competitor-analysis-grid.png'

import CS1CAHome1 from '../assets/case-study-1/ca-square-enix-home.png'
import CS1CAHome2 from '../assets/case-study-1/ca-capcom-home.png'
import CS1CAHome3 from '../assets/case-study-1/ca-sega-home.png'
import CS1CAHome4 from '../assets/case-study-1/ca-game-home.png'

import CS1CAProducts1 from '../assets/case-study-1/ca-square-enix-products.png'
import CS1CAProducts2 from '../assets/case-study-1/ca-capcom-products.png'
import CS1CAProducts3 from '../assets/case-study-1/ca-sega-products.png'
import CS1CAProducts4 from '../assets/case-study-1/ca-game-products.png'

import CS1CASingle1 from '../assets/case-study-1/ca-square-enix-single-product.png'
import CS1CASingle2 from '../assets/case-study-1/ca-capcom-single-product.png'
import CS1CASingle3 from '../assets/case-study-1/ca-sega-single-product.png'
import CS1CASingle4 from '../assets/case-study-1/ca-game-single-product.png'

import userTestingImage from '../assets/case-study-1/user-testing.avif'

import cardSortImage from '../assets/case-study-1/card-sort.png'

import ideationImage1 from '../assets/case-study-1/ideation-crazy-eights.jpg'
import ideationImage2 from '../assets/case-study-1/ideation-crazy-eights-2.jpg'
import ideationImage3 from '../assets/case-study-1/ideation-crazy-eights-3.jpg'
import ideationImage4 from '../assets/case-study-1/ideation-crazy-eights-4.jpg'
import ideationImage5 from '../assets/case-study-1/ideation-crazy-eights-5.jpg'

import lowWireframe1 from '../assets/case-study-1/wireframes-low-desktop-annotated.png'
import lowWireframe2 from '../assets/case-study-1/wireframes-low-mobile-annotated.png'

import midWireframeDesktop1 from '../assets/case-study-1/wireframe-mid-desktop-homepage.png'
import midWireframeDesktop2 from '../assets/case-study-1/wireframe-mid-desktop-products-page.png'
import midWireframeDesktop3 from '../assets/case-study-1/wireframe-mid-desktop-product.png'
import midWireframeDesktop4 from '../assets/case-study-1/wireframe-mid-desktop-nav.png'
import midWireframeMobile1 from '../assets/case-study-1/wireframe-mid-mobile-homepage.png'
import midWireframeMobile2 from '../assets/case-study-1/wireframe-mid-mobile-products-page.png'
import midWireframeMobile3 from '../assets/case-study-1/wireframe-mid-mobile-product.png'
import midWireframeMobile4 from '../assets/case-study-1/wireframe-mid-mobile-filters.png'

import highWireframeDesktop1 from '../assets/case-study-1/wireframe-high-desktop-homepage.png'
import highWireframeDesktop2 from '../assets/case-study-1/wireframe-high-desktop-products-page.png'
import highWireframeDesktop3 from '../assets/case-study-1/wireframe-high-desktop-product.png'
import highWireframeMobile1 from '../assets/case-study-1/wireframe-high-mobile-homepage.png'
import highWireframeMobile2 from '../assets/case-study-1/wireframe-high-mobile-products-page.png'
import highWireframeMobile3 from '../assets/case-study-1/wireframe-high-mobile-product.png'

import CS1styleGuide from '../assets/case-study-1/ui-style-guide.png'
import CS1typography from '../assets/case-study-1/ui-typography.png'
import CS1alignment from '../assets/case-study-1/ui-alignment.png'
import CS1accessibility from '../assets/case-study-1/ui-accessibility.png'

// INTRODUCTION

const CS1introText = [
  {
    id: 1,
    text: "The gaming industry is a rapidly growing sector of the entertainment industry. The industry has expanded beyond traditional consoles and PC games, with the rise of mobile gaming and the emergence of new platforms like virtual reality. In 2021, the global video game industry was worth an estimated $179.7 billion, with continued growth expected in the coming years.",
  },
  {
    id: 2,
    text: "The industry has seen significant changes over the past decade, driven by advances in technology and changes in consumer behaviour. One major change has been video game monetisation, with many games incorporating micro transactions, downloadable content, and other forms of in-game purchases to generate revenue.",
  },
  {
    id: 3,
    text: "The past decade has also seen significant changes in the business models of the industry, with the rise of free-to-play games and the growth of digital distribution platforms like Steam and the Epic Games Store. These changes have led to increased accessibility to games and a shift away from physical retail sales.",
  },
  {
    id: 4,
    text: "The COVID-19 pandemic also had a significant impact on the gaming industry, with more people turning to video games for entertainment and social interaction while stuck at home. This led to increased sales for game developers and publishers, as well as a surge in demand for gaming hardware.",
  },
  {
    id: 5,
    text: "In this case study I am going to analyse the Bandai Namco store website, a leader in the video game industry, and make improvements to the website to encourage users to purchase Bandai Namco products from there instead of competitor websites. Using the website, I noticed several problems, including difficulty in finding certain products and confusion on how to find certain products. I will be taking on the role of UX/UI designer in this project.",
  }
]

export { CS1introText };

const CS1introImages = [
  {
    id: 1,
    img: CS1screenshotDesktop,
    alt: "Bandai Namco Screenshot Desktop"
  },
  {
    id: 2,
    img: CS1screenshotMobile,
    alt: "Bandai Namco Screenshot Mobile"
  }
]

export { CS1introImages };


// COMPETITOR ANALYSIS

const CS1competiorAnalysisText = [
  {
    id: 1,
    text: "A competitor analysis is a comparison between websites of the same and similar industries. It collects data about a competitor’s company and their products. The main reason competitor analyses are done is to gain insights from the collected data, minimise risk in design decisions, and make informed design decisions."
  },
  {
    id: 2,
    text: "Direct competitors are companies that have the same customers and offer the same products or service as your company. Bandai Namco’s direct competitors are Square Enix, Capcom, and Sega, as they are all companies that make games as well as sell games and game merchandise."
  },
  {
    id: 3,
    text: "Indirect competitors are companies that offer something similar to your company. An  indirect competitor of Bandai Namco is Game, as Game does not make games but sells games and game merchandise."
  },
  {
    id: 4,
    text: "Here I have compared features of Bandai Namco with its competitors and have noted strengths and weaknesses of the competitor’s online stores."
  },
]

export { CS1competiorAnalysisText };

const CS1competitorAnalysisImages = [
  {
    id: 1,
    img: CS1CompetitorAnalysis1,
    alt: "Square Enix Competitor Analysis"
  },
  {
    id: 2,
    img: CS1CompetitorAnalysis2,
    alt: "Capcom Competitor Analysis"
  },
  {
    id: 3,
    img: CS1CompetitorAnalysis3,
    alt: "Sega Competitor Analysis"
  },
  {
    id: 4,
    img: CS1CompetitorAnalysis4,
    alt: "Game Competitor Analysis"
  },
  {
    id: 5,
    img: CS1CompetitorAnalysisGrid,
    alt: "Competitor Analysis Grid"
  }
]

export { CS1competitorAnalysisImages };

const CS1CAImagesHomepage = [
  {
    id: 1,
    img: CS1CAHome1,
    alt: "Square Enix Homepage"
  },
  {
    id: 2,
    img: CS1CAHome2,
    alt: "Capcom Homepage"
  },
  {
    id: 3,
    img: CS1CAHome3,
    alt: "Sega Homepage"
  },
  {
    id: 4,
    img: CS1CAHome4,
    alt: "Game Homepage"
  },
]
export { CS1CAImagesHomepage };

const CS1CAImagesProductsPage = [
  {
    id: 1,
    img: CS1CAProducts1,
    alt: "Square Enix Products Page"
  },
  {
    id: 2,
    img: CS1CAProducts2,
    alt: "Capcom Products Page"
  },
  {
    id: 3,
    img: CS1CAProducts3,
    alt: "Sega Products Page"
  },
  {
    id: 4,
    img: CS1CAProducts4,
    alt: "Game Products Page"
  },
]
export { CS1CAImagesProductsPage };

const CS1CAImagesSingleProduct = [
  {
    id: 1,
    img: CS1CASingle1,
    alt: "Square Enix Single Product Page"
  },
  {
    id: 2,
    img: CS1CASingle2,
    alt: "Capcom Single Product Page"
  },
  {
    id: 3,
    img: CS1CASingle3,
    alt: "Sega Single Product Page"
  },
  {
    id: 4,
    img: CS1CASingle4,
    alt: "Game Single Product Page"
  },
]
export { CS1CAImagesSingleProduct };

// USER TESTING

const CS1userTestingText = [
  {
    id: 1,
    text: "User Testing is where users test the website by following set journeys and questions they must complete. This is a helpful method and I decided to use it because it can provide insight into which part of the website needs focus and improvement."
  },
  {
    id: 2,
    text: "I asked questions on the user’s impressions of the website and asked them to complete three tasks that would be commonly carried out when using the website. I carried out the user testing via a recorded video call, where I could watch the footage back to take notes."
  },
]

export { CS1userTestingText };

const CS1userTestingImages = [
  {
    id: 1,
    img: userTestingImage,
    alt: "User Testing"
  },
]

export { CS1userTestingImages };

// USER TESTING - WEBSITE IMPRESSIONS

const CS1websiteImpressionsText = [
  {
    id: 1,
    text: "Website Impressions"
  },
  {
    id: 2,
    text: "The website mostly had good first impressions. All felt that someone would use the website for purchasing games, merchandise, and finding information on games, therefore the purpose of the website is very clear. All participants found the website similar to another one, whether it was competitor websites, other gaming websites, or just a standard e-commerce website. One participant stated that they do not buy games from these kinds of websites, however all others said nothing would keep them from using the website."
  },
  {
    id: 3,
    text: "Users appreciated the clear and predictable layout of the website, although the website could find a better way of showcasing their range of products."
  },
  {
    id: 4,
    text: "One user experienced long loading times on the image carousel on a product page.It didn’t really impact their experience, but they found it a little confusing seeing an empty thick black bar on the page and so ranked it 3/10."
  },
  {
    id: 5,
    text: "One participant didn’t understand/it wasn’t clear why All Games was displayed in the order it was, and this is because the filter said ‘Select One’ instead of being the option the products were being displayed in e.g. ‘Best Sellers’."
  },
  {
    id: 6,
    text: "The simple design was praised, along with the standard layout. One participant liked the product card layout and that there were hero banners on the product pages. One participant liked the colour scheme, whilst another liked the red add to cart button, another one stating that they liked that the prices were clear. One participant liked that the individual product pages have lots of images on them and lots of information."
  },
  {
    id: 7,
    text: "Suggestions"
  },
  {
    id: 8,
    text: "1) Add a dark mode"
  },
  {
    id: 9,
    text: "2) Add game type categories to product page filters"
  },
  {
    id: 10,
    text: "3) Remove top navigation bar to footer or replace with ‘Back to main website’ button"
  },
  {
    id: 11,
    text: "4) Rework individual product page top as it is not very accessible, improve the navigation bar"
  },
  {
    id: 12,
    text: "5) Add a guest checkout option."
  },
  {
    id: 13,
    text: "Three participants said they would use the website if the changes were made. One replied only if they had no other choice, and one said they still would not use the website. The website therefore needs to be on par with competitor websites."
  },
]

export { CS1websiteImpressionsText };

// USER TESTING - TASKS

const CS1tasksText = [
  {
    id: 1,
    text: "Tasks"
  },
  {
    id: 2,
    text: "Task 1: Finding New Products"
  },
  {
    id: 3,
    text: "New products carousel is good, but perhaps it should be an option in the navigation bar as well."
  },
  {
    id: 4,
    text: "Having the new products on the homepage is a good idea."
  },
  {
    id: 5,
    text: "Task 2: Finding a Season Pass"
  },
  {
    id: 6,
    text: "There needs to be a clearer and easier way of finding season passes. One participant was unsuccessful in finding a season pass. Two participants ranked successful with difficulty, as one took a while to find it, whilst the other only found it by accident. 4/5 participants found finding the Season Pass the hardest task. One participant said there was no category for season passes, and that the images on the product pages were all visually similar so the season pass product did not stand out. Other participants also shared that they would like it to be sign posted somewhere."
  },
  {
    id: 7,
    text: "Potential Solutions:"
  },
  {
    id: 8,
    text: "1) The top of the game products page should have highlighted items and at least one of those items should be a season pass"
  },

  {
    id: 9,
    text: "2) Season passes should be its own category that users can browse"
  },
  {
    id: 10,
    text: "3) Season Passes should be a filter option under ‘Product Type’ on the game products page"
  },
  {
    id: 11,
    text: "4) Add a tag on the image (like a new tag) to show than an option is a season pass for those who are not reading the titles and are just looking at the images."
  },
  {
    id: 12,
    text: "Task 3: Buying a product"
  },
  {
    id: 13,
    text: "Adding a product to the basket is very straightforward, but the log in/create an account page creates more issues than one, and so should be addressed."
  },
  {
    id: 14,
    text: "On the checkout page log in screen, one user clicked the logo to return to the homepage, but instead it took them to the main Bandai Namco website instead. Impacted experience 5/10, as they wanted to go back to add something else to the cart, but it sent them to a different website. Perhaps have two buttons for which website to return to."
  },
  {
    id: 15,
    text: "Having a guest checkout would be desirable.One user did not like that they had to make an account to be able to make a purchase, and said it impacted their experience 8/10."
  },
]

export { CS1tasksText };


// USER RESEARCH - CARD SORTING

const CS1cardSortText = [
  {
    id: 1,
    text: "Card Sorting"
  },
  {
    id: 2,
    text: "Card sorting is an ideation technique that involves asking users to organise information. I used card sorting to help determine what is suitable to include in the top-level navigation bar of the Bandai Namco Store website, as I received feedback during my research phase that the navigation should be improved."
  },
  {
    id: 3,
    text: "Participants were asked to sort seven website features and rank them from least to most important feature to be included in the navigation bar. The features were Platforms, Collectibles, Search, Games, Season Pass, Deals, and DLC."
  },
  {
    id: 4,
    text: "Across all participants, Search and Games were consistently ranked in the most important three. Conversely, Collectibles, DLC, and Season Pass were usually ranked within the least important three."
  },
  {
    id: 5,
    text: "I took the average ranks of all participant’s results. The lower the score the more important the feature (Most important = rank 1, Least important = rank 7):"
  },
  {
    id: 6,
    text: "Search: 1.5, Games: 1.8, Deals: 3.8, Platforms: 4, DLC: 5.3, Season Pass: 5.5, Collectibles: 6."
  },
  {
    id: 7,
    text: "Looking at these results and taking into consideration competitor websites and the user testing feedback, I believe the navigation could be Games, Franchises, Platforms, Collectibles, Deals, and Search, and have a slightly different structure within the menu drop downs."
  },
  {
    id: 8,
    text: "Instead of Games listing all games and popular franchises, it could be cleaned up to list ‘All Games’, ‘Genres’, ‘DLC’ and ‘Season Passes’, with Genres giving another drown down list on hover."
  },
  {
    id: 9,
    text: "Franchises would become a top level option, giving users the option to browse ‘All Franchises’, or select one of the popular ones on the list. This way, collectables are included in the results rather than strictly games."
  },
  {
    id: 10,
    text: "Platforms, Collectibles, and Deals would remain the same."
  },
  {
    id: 11,
    text: "On product pages, the filter options would be Franchise (list games), Platform (list platforms), Genre (list genres), Product Type (Games, DLC, Season Pass, Collectables), and Sort By (Decreasing Price, Ascending Price, New Items, Titles (A-Z), Titles (Z-A). Currently, there is inconsistency between pages on what filters are present, and this would help to address the feedback of it being difficult to find certain products."
  },
  {
    id: 12,
    text: "All of these changes would be easily implemented for mobile, as the navigation would be present in the mobile menu and the filters would still be at the bottom of the page but with more options."
  },
]

export { CS1cardSortText };

const CS1cardSortImages = [
  {
    id: 1,
    img: cardSortImage,
    alt: "Card Sort"
  },
]

export { CS1cardSortImages };


// IDEATION - CRAZY EIGHTS

const CS1ideationText = [
  {
    id: 1,
    text: "Crazy Eights"
  },
  {
    id: 2,
    text: "I have chosen the ideation technique of Crazy Eights. Crazy Eights is a design sprint, where you are given one minute to sketch out one design, ending with eight designs in eight minutes. From analysing the results of my business research and user research, I focused on the homepage and the individual product page, as those were pages that needed the most improvement. This ideation will benefit my design process as it has allowed me to visually consider different approaches for the homepage and individual product page."
  },
  {
    id: 3,
    text: "Several of the designs feature a slimmer hero banner, as I noticed a competitor had done the same, and one user during my user research commented on it being too large."
  },
  {
    id: 4,
    text: "I played with different grid columns for the desktop homepage’s New Products section, switching between three and four, and whether the section would be a carousel or not. On mobile view, the New Products is below the Hero Banner, but on desktop there are images of various franchises first: I think it would be a good idea to remove that and move up the New Products section. In the second sketch, below the New Products section there is another visually similar section: this would display Popular Franchises."
  },
  {
    id: 5,
    text: "The fourth sketch details the new navigation system, taking inspiration from competitor websites and user feedback. The end result would be multi-level drop downs, to make the navigation of the website easier and less confusing for the user."
  },
  {
    id: 6,
    text: "I also included a reworked top nav bar in the fifth design, rather than completely removing it, which would include a button to leave the store and return to the main Bandai Namco website, to make it clearer that you are leaving the store website if you click on that nav bar as it is currently not clear."
  },
  {
    id: 7,
    text: "The final three designs are reworking the individual product page. The first two designs remove the image and information to below the hero banner, to increase accessibility. The final design removes the hero banner entirely. The final two designs have an image carousel, like on the existing website, and all three designs have another navigation below that to display specific information and prevent scrolling down to find certain information, as several users found themselves scrolling down the product page in search of information."
  },
]

export { CS1ideationText };

const CS1ideationImages = [
  {
    id: 1,
    img: ideationImage1,
    alt: "Crazy Eights"
  },
  {
    id: 2,
    img: ideationImage2,
    alt: "Crazy Eights Homepage"
  },
  {
    id: 3,
    img: ideationImage3,
    alt: "Crazy Eights Navigation"
  },
  {
    id: 4,
    img: ideationImage4,
    alt: "Crazy Eights New Nav Bar"
  },
  {
    id: 5,
    img: ideationImage5,
    alt: "Crazy Eights Product Page"
  },
]

export { CS1ideationImages };


// LOW FIDELITY WIREFRAMES

const CS1lowFidelityWireframes = [
  {
    id: 1,
    img: lowWireframe1,
    alt: "Low Fidelity Desktop Wireframe"
  },
  {
    id: 2,
    img: lowWireframe2,
    alt: "Low Fidelity Mobile Wireframe"
  }
]

export { CS1lowFidelityWireframes };

// MID FIDELITY WIREFRAMES

const CS1midFidelityWireframes = [
  {
    id: 1,
    img: midWireframeDesktop1,
    alt: "Mid Fidelity Desktop Wireframe Homepage"
  },
  {
    id: 2,
    img: midWireframeDesktop2,
    alt: "Mid Fidelity Desktop Wireframe Products Page"
  },
  {
    id: 3,
    img: midWireframeDesktop3,
    alt: "Mid Fidelity Desktop Wireframe Individual Product Page"
  },
  {
    id: 4,
    img: midWireframeDesktop4,
    alt: "Mid Fidelity Desktop Wireframe Navigation Drop Down"
  },
  {
    id: 5,
    img: midWireframeMobile1,
    alt: "Mid Fidelity Mobile Wireframe Homepage"
  },
  {
    id: 6,
    img: midWireframeMobile2,
    alt: "Mid Fidelity Mobile Wireframe Products Page"
  },
  {
    id: 7,
    img: midWireframeMobile3,
    alt: "Mid Fidelity Mobile Wireframe Individual Product Page"
  },
  {
    id: 8,
    img: midWireframeMobile4,
    alt: "Mid Fidelity Mobile Wireframe Filters"
  }
]

export { CS1midFidelityWireframes };

// HIGH FIDELITY WIREFRAMES

const CS1highFidelityWireframes = [
  {
    id: 1,
    img: highWireframeDesktop1,
    alt: "High Fidelity Desktop Wireframe Homepage"
  },
  {
    id: 2,
    img: highWireframeDesktop2,
    alt: "High Fidelity Desktop Wireframe Products Page"
  },
  {
    id: 3,
    img: highWireframeDesktop3,
    alt: "High Fidelity Desktop Wireframe Individual Product Page"
  },
  {
    id: 4,
    img: highWireframeMobile1,
    alt: "High Fidelity Mobile Wireframe Homepage"
  },
  {
    id: 5,
    img: highWireframeMobile2,
    alt: "High Fidelity Mobile Wireframe Products Page"
  },
  {
    id: 6,
    img: highWireframeMobile3,
    alt: "High Fidelity Mobile Wireframe Individual Product Page"
  }
]

export { CS1highFidelityWireframes };


// UI PHASE

const CS1uiPhaseImages = [
  {
    id: 1,
    img: CS1styleGuide,
    alt: "Style Guide"
  },
  {
    id: 2,
    img: CS1typography,
    alt: "Typography"
  },
  {
    id: 3,
    img: CS1alignment,
    alt: "Alignment and Grid"
  },
  {
    id: 4,
    img: CS1accessibility,
    alt: "Accessibility"
  },
]

export { CS1uiPhaseImages }


// CONCLUSION

const CS1conclusionText = [
  {
    id: 1,
    text: "The research phase of my case study was particularly useful, as I researched competitor websites and saw how they displayed and advertised their products. The user research process also went well as I gained a lot of valuable insights into the user experience, which allowed me to identify pain points and areas for improvement, as well as see what the user deemed as important features."
  },
  {
    id: 2,
    text: "The ideation and wireframing phases were also useful, as I was able to come up with creative new ideas to enhance the website's functionality and user experience."
  },
  {
    id: 3,
    text: "Overall, I thoroughly enjoyed working on this case study and it was a practical experience of the design process which allowed me to learn a lot. I believe I was able to meet my objective of improving the Bandai Namco Store website thanks to the design steps I took, but to see if my design truly improved the website, I would do user testing and gather further feedback to help improve the new design."
  },
]

export { CS1conclusionText };