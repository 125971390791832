// Abeno Content

// Image Imports

import CS3screenshotHome from '../assets/case-study-3/abeno-screenshot-home.png'
import CS3screenshotAbout from '../assets/case-study-3/abeno-screenshot-about.png'
import CS3screenshotMenu from '../assets/case-study-3/abeno-screenshot-menu.png'

import CS3CompetitorAnalysis1 from '../assets/case-study-3/ca-okan.png'
import CS3CompetitorAnalysis2 from '../assets/case-study-3/ca-tenshi.png'
import CS3CompetitorAnalysis3 from '../assets/case-study-3/ca-cocoro.png'
import CS3CompetitorAnalysis4 from '../assets/case-study-3/ca-wagamama.png'
import CS3CompetitorAnalysisGrid from '../assets/case-study-3/ca-grid.png'

import CS3CAHome1 from '../assets/case-study-3/ca-okan-home.png'
import CS3CAHome2 from '../assets/case-study-3/ca-tenshi-home.png'
import CS3CAHome3 from '../assets/case-study-3/ca-cocoro-home.png'
import CS3CAHome4 from '../assets/case-study-3/ca-wagamama-home.png'

import CS3CAAbout1 from '../assets/case-study-3/ca-tenshi-about.png'
import CS3CAAbout2 from '../assets/case-study-3/ca-cocoro-about.png'
import CS3CAAbout3 from '../assets/case-study-3/ca-wagamama-about.png'

import CS3CAMenu1 from '../assets/case-study-3/ca-okan-menu.png'
import CS3CAMenu2 from '../assets/case-study-3/ca-tenshi-menu.png'
import CS3CAMenu3 from '../assets/case-study-3/ca-cocoro-menu.png'
import CS3CAMenu4 from '../assets/case-study-3/ca-wagamama-menu.png'

import CS3UserResearchQuestionnaire from '../assets/case-study-3/user-research-questionnaire.png'

import CS3Ideation from '../assets/case-study-3/ideation-mind-map.jpg'

import CS3lowWireframes from '../assets/case-study-3/low-fidelity-wireframes.png'

import CS3highWireframe1 from '../assets/case-study-3/hw-home.png'
import CS3highWireframe2 from '../assets/case-study-3/hw-about.png'
import CS3highWireframe3 from '../assets/case-study-3/hw-menu.png'

import CS3styleGuide from '../assets/case-study-3/ui-style-guide.png'
import CS3603010Rule from '../assets/case-study-3/ui-60-30-10.png'
import CS3typography from '../assets/case-study-3/ui-typography.png'
import CS3accessibility from '../assets/case-study-3/ui-accessibility.png'

// INTRODUCTION

const CS3introText = [
  {
    id: 1,
    text: "London’s Japanese restaurant scene has seen growth over the past decade, catering to increasingly diverse tastes amongst the dining public in and visiting the city. With its vibrant and varied dishes, Japanese cuisine has become a popular choice for food lovers in London. However, attractive websites and positive restaurant descriptions and reviews have become increasingly important, particularly amongst the younger generations who want to be seen visiting stylish places.",
  },
  {
    id: 2,
    text: "My chosen restaurant is Abeno, Europe’s first specialist Okonomi-Yaki restaurant. Although the restaurant is the world’s first Michelin-listed Okonomi-Yaki restaurant, the website does not reflect this and instead looks outdated and unappealing. My goal therefore is to create a new website design that will help Abeno’s website to impress customers not only with its description but with it’s visuals too.",
  },
  {
    id: 3,
    text: "Going through Abeno’s website, I identified several problems that would discourage customers from dining with them.",
  },
  {
    id: 4,
    text: "The homepage feels very plain, dated and uninteresting, which could make potential customers question the quality of the restaurant. The navigation bar is also not very visible against the hero banner. Additionally, not everyone will bother to watch the YouTube video, so it is not good to rely on it to convey information.",
  },
  {
    id: 5,
    text: "On the about page, whilst the food images look good, they are too large which results in a long page. The images are also partially covered by text boxes, which does not always look good. ",
  },
  {
    id: 6,
    text: "Whilst having the menu in two languages ia a good idea, the menu itself looks very plain: it lacks branding and has a lot to read, but offers no photos. Also, it can be bothersome for some potential customers to download the menu instead of being able to see it on the website.",
  },
  {
    id: 7,
    text: "Finally, across the whole website the red background is overpowering.",
  }
]

export { CS3introText };

const CS3introImages = [
  {
    id: 1,
    img: CS3screenshotHome,
    alt: "Abeno Homepage Screenshot"
  },
  {
    id: 2,
    img: CS3screenshotAbout,
    alt: "Abeno About Page Screenshot"
  },
  {
    id: 3,
    img: CS3screenshotMenu,
    alt: "Abeno Menu Screenshot"
  }
]

export { CS3introImages };

// COMPETITOR ANALYSIS

const CS3competiorAnalysisText = [
  {
    id: 1,
    text: "A competitor analysis is a comparison between websites of the same and similar industries. It collects data about a competitor’s company and their products. The main reason competitor analyses are done is to gain insights from the collected data, minimise risk in design decisions, and make informed design decisions."
  },
  {
    id: 2,
    text: "Direct competitors are companies that have the same customers and offer the same products or service as your company. For direct competitors I have chosen Okan and Tenshi, as both of these London restaurants also sell okonomiyaki."
  },
  {
    id: 3,
    text: "Indirect competitors are companies that offer something similar to your company. For indirect competitors I have chosen Cocoro, as it is another Japanese restaurant located extremely close to Abeno, and so potential customers could choose to eat there instead, and Wagamama, as although lacks authenticity, it is a well known Japanese restaurant chain."
  },
]

export { CS3competiorAnalysisText };

const CS3competitorAnalysisImages = [
  {
    id: 1,
    img: CS3CompetitorAnalysis1,
    alt: "Okan Competitor Analysis"
  },
  {
    id: 2,
    img: CS3CompetitorAnalysis2,
    alt: "Tenshi Competitor Analysis"
  },
  {
    id: 3,
    img: CS3CompetitorAnalysis3,
    alt: "Cocoro Competitor Analysis"
  },
  {
    id: 4,
    img: CS3CompetitorAnalysis4,
    alt: "Wagamama Competitor Analysis"
  },
  {
    id: 5,
    img: CS3CompetitorAnalysisGrid,
    alt: "Competitor Analysis Grid"
  }
]

export { CS3competitorAnalysisImages };

const CS3CAImagesHomepage = [
  {
    id: 1,
    img: CS3CAHome1,
    alt: "Okan Homepage"
  },
  {
    id: 2,
    img: CS3CAHome2,
    alt: "Tenshi Homepage"
  },
  {
    id: 3,
    img: CS3CAHome3,
    alt: "Cocoro Homepage"
  },
  {
    id: 4,
    img: CS3CAHome4,
    alt: "Wagamama Homepage"
  },
]
export { CS3CAImagesHomepage };

const CS3CAImagesAboutPage = [
  {
    id: 1,
    img: CS3CAAbout1,
    alt: "Tenshi About Page"
  },
  {
    id: 2,
    img: CS3CAAbout2,
    alt: "Cocoro About Page"
  },
  {
    id: 3,
    img: CS3CAAbout3,
    alt: "Wagamama About Page"
  },
]
export { CS3CAImagesAboutPage };

const CS3CAImagesMenu = [
  {
    id: 1,
    img: CS3CAMenu1,
    alt: "Okan Menu"
  },
  {
    id: 2,
    img: CS3CAMenu2,
    alt: "Tenshi Menu"
  },
  {
    id: 3,
    img: CS3CAMenu3,
    alt: "Cocoro Menu"
  },
  {
    id: 4,
    img: CS3CAMenu4,
    alt: "Wagamama Menu"
  },
]
export { CS3CAImagesMenu };


// USER RESEARCH

const CS3userResearchImages = [
  {
    id: 1,
    img: CS3UserResearchQuestionnaire,
    alt: "User Research Questionnaire"
  },
]

export { CS3userResearchImages };

// IDEATION - MIND MAP

const CS3ideationText = [
  {
    id: 1,
    text: "I have chosen the ideation technique of Mind Mapping. This technique is useful for focusing on solutions without getting too deep into the details. Looking at the results of the survey, I identified key issues that often came up in the feedback and thought of ideas on how to solve them."
  },
]

export { CS3ideationText };

const CS3ideationImages = [
  {
    id: 1,
    img: CS3Ideation,
    alt: "Mind Map"
  },
]

export { CS3ideationImages };

// LOW FIDELITY WIREFRAMES

const CS3lowFidelityWireframes = [
  {
    id: 1,
    img: CS3lowWireframes,
    alt: "Low Fidelity Wireframes"
  },
]

export { CS3lowFidelityWireframes };

// HIGH FIDELITY WIREFRAMES

const CS3highFidelityWireframes = [
  {
    id: 1,
    img: CS3highWireframe1,
    alt: "High Fidelity Wireframe Homepage"
  },
  {
    id: 2,
    img: CS3highWireframe2,
    alt: "High Fidelity Wireframe About Page"
  },
  {
    id: 3,
    img: CS3highWireframe3,
    alt: "High Fidelity Wireframe Menu Page"
  }
]

export { CS3highFidelityWireframes };


// UI PHASE

const CS3uiPhaseImages = [
  {
    id: 1,
    img: CS3styleGuide,
    alt: "Style Guide"
  },
  {
    id: 2,
    img: CS3603010Rule,
    alt: "60-30-10 Rule"
  },
  {
    id: 3,
    img: CS3typography,
    alt: "Typography"
  },
  {
    id: 4,
    img: CS3accessibility,
    alt: "Accessibility"
  },
]

export { CS3uiPhaseImages }

// CONCLUSION 

const CS3conclusionText = [
  {
    id: 1,
    text: "The survey I used for user research proved highly beneficial for this case study, as it provided feedback on areas that needed improvement. Researching competitors was also useful as I could see how they displayed information and their use of brand colours. "
  },
  {
    id: 2,
    text: "By using a mind map for ideation, I was able to draw inspiration from the user feedback, and wireframing allowed me to delve deeper into these ideas."
  },
  {
    id: 3,
    text: "For the colour scheme, I decided not to deviate too much from Abeno’s existing colour theme, instead choosing to rework the way the colours were presented. I also incorporated Abeno’s red colour into the menu, to help with the branding issue."
  },
  {
    id: 4,
    text: "Overall, I believe I was able to meet my objective of modernising Abeno’s website thanks to the design steps I took. To see if my design truly improved the website, I would do user testing and gather further feedback to help improve the new design."
  }
]

export { CS3conclusionText };