// Mobile Menu

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const MobileMenu = (props) => {

  const { toggleyOverflow } = props;

  const [openMenu, setopenMenu] = useState(false);

  const toggleMenu = () => {
    setopenMenu(!openMenu);
    toggleyOverflow();
  }

  return (
    <div className="mobile-menu-container">
      <div className={openMenu ? "hamburger-menu showMenu" : "hamburger-menu"}>
        <ul>
          <Link to="/" onClick={toggleMenu}><li>Home</li></Link>
          <Link to="/about" onClick={toggleMenu}><li>About</li></Link>
          <Link to="mailto:calyston.14@outlook.com"><button className="cta">Get In Touch <FontAwesomeIcon icon={faEnvelope} /></button></Link>
        </ul>
      </div>
      <button className="hamburger" onClick={toggleMenu}>
        <FontAwesomeIcon className={openMenu ? "menu-icon displayNone" : "menu-icon"} icon={faBars} />
        <FontAwesomeIcon className={openMenu ? "close-icon" : "close-icon displayNone"} icon={faXmark} />
      </button>
    </div>
  )
}

export default MobileMenu;