import '../sass/main.scss';
import React from 'react';
import About from './About';
// import Testimonies from './Testimonies';

const AboutPage = () => {
  return (
    <section className="page-container">
      <About />
      {/* <Testimonies /> */}
    </section>
  )
}

export default AboutPage;