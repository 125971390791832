import '../sass/main.scss';
import React from 'react';
import { Link } from 'react-router-dom';
// import About from './About';

// Case Study Images
import bandaiNamcoImage from '../assets/case-study-1/case-study-1-image.png'
import opodoImage from '../assets/case-study-2/case-study-2-image.png'
import abenoImage from '../assets/case-study-3/case-study-3-image.png'

// UI Project Images
import eyesOnMeImage from '../assets/ui-project-1/ui-project-1-image.png'

const Home = () => {
  return (
    <div>
      <section id="scroll-container">
        <h1 id="scroll-text">Hello, I'm Christine, a UX / UI Designer and Web Developer.</h1>
      </section>
      <section className="page-container">
        <h1 id="mobile-header">Hello, I'm Christine, a UX / UI Designer and Web Developer.</h1>
        {/* <p className="intro-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}

        {/* Projects Grid */}
        <article className="home-page">
          <h2>Case Studies</h2>
          <article className="home-grid">

            <Link to="/case-study-1">
              <div className="home-grid-item">
                <img src={bandaiNamcoImage} alt="Bandai Namco Store" />
                <div className="home-grid-text">
                  <h5>Bandai Namco Store</h5>
                  <p>UX/UI Design</p>
                </div>
              </div>
            </Link>

            <Link to="/case-study-2">
              <div className="home-grid-item">
                <img src={opodoImage} alt="Opodo" />
                <div className="home-grid-text">
                  <h5>Opodo</h5>
                  <p>UX/UI Design</p>
                </div>
              </div>
            </Link>

            <Link to="/case-study-3">
              <div className="home-grid-item">
                <img src={abenoImage} alt="Abeno" />
                <div className="home-grid-text">
                  <h5>Abeno</h5>
                  <p>UX/UI Design</p>
                </div>
              </div>
            </Link>

            <Link to="/ui-project-1">
              <div className="home-grid-item">
                <img src={eyesOnMeImage} alt="Social Media App" />
                <div className="home-grid-text">
                  <h5>Social Media App</h5>
                  <p>UI Design</p>
                </div>
              </div>
            </Link>
          </article>
        </article>
        {/* <About /> */}
      </section>
    </div>

  )
}

export default Home;