// Opodo Case Study

// Image Imports

import CS2screenshotHome from '../assets/case-study-2/opodo-screenshot-homepage.png'
import CS2screenshotHotel from '../assets/case-study-2/opodo-screenshot-hotel.png'
import CS2screenshotBooking1 from '../assets/case-study-2/opodo-screenshot-booking-1.png'
import CS2screenshotBooking2 from '../assets/case-study-2/opodo-screenshot-booking-2.png'

import CS2CompetitorAnalysis1 from '../assets/case-study-2/ca-vrbo.png'
import CS2CompetitorAnalysis2 from '../assets/case-study-2/ca-super-travel.png'
import CS2CompetitorAnalysis3 from '../assets/case-study-2/ca-hotelscom.png'
import CS2CompetitorAnalysis4 from '../assets/case-study-2/ca-googletravel.png'
import CS2CompetitorAnalysisGrid from '../assets/case-study-2/ca-grid.png'

import CS2CAHome1 from '../assets/case-study-2/ca-vrbo-homepage.png'
import CS2CAHome2 from '../assets/case-study-2/ca-super-homepage.png'
import CS2CAHome3 from '../assets/case-study-2/ca-hotels-homepage.png'
import CS2CAHome4 from '../assets/case-study-2/ca-google-travel-homepage.png'

import CS2CAHotel1 from '../assets/case-study-2/ca-vrbo-hotel-page.png'
import CS2CAHotel2 from '../assets/case-study-2/ca-hotels-hotel-page.png'
import CS2CAHotel3 from '../assets/case-study-2/ca-google-travel-hotel-page.png'

import CS2CABooking1 from '../assets/case-study-2/ca-vrbo-booking-page.png'
import CS2CABooking2 from '../assets/case-study-2/ca-hotels-booking-page.png'

import CS2UserResearchQuestionnaire from '../assets/case-study-2/user-research-questionnaire.png'
import CS2RedRouteAnalysis from '../assets/case-study-2/user-research-redroute.png'

import CS2Ideation from '../assets/case-study-2/ideation.jpg'
import CS2CrazyEights1 from '../assets/case-study-2/crazy-eights-1.png'
import CS2CrazyEights2 from '../assets/case-study-2/crazy-eights-2.png'
import CS2CrazyEights3 from '../assets/case-study-2/crazy-eights-3.png'

import CS2lowWireframe1 from '../assets/case-study-2/lw-homepage.png'
import CS2lowWireframe2 from '../assets/case-study-2/lw-hotel.png'
import CS2lowWireframe3 from '../assets/case-study-2/lw-booking.png'

import CS2midWireframe1 from '../assets/case-study-2/mw-homepage.png'
import CS2midWireframe2 from '../assets/case-study-2/mw-hotel.png'
import CS2midWireframe3 from '../assets/case-study-2/mw-booking.png'

import CS2highWireframe1 from '../assets/case-study-2/hw-homepage.png'
import CS2highWireframe2 from '../assets/case-study-2/hw-hotel.png'
import CS2highWireframe3 from '../assets/case-study-2/hw-booking.png'

import CS2styleGuide from '../assets/case-study-2/ui-style-guide.png'
import CS2typography from '../assets/case-study-2/ui-typography.png'
import CS2alignment from '../assets/case-study-2/ui-alignment.png'
import CS2accessibility from '../assets/case-study-2/ui-accessibility.png'

// INTRODUCTION

const CS2introText = [
  {
    id: 1,
    text: "Over the past decade, the hotel booking industry has undergone significant changes, revolutionizing the way people book and plan their travel accommodations. With the rise of online travel agencies, mobile devices, and alternative accommodations, consumers now have more options than ever before when it comes to booking their next holiday. This growth in the online hotel booking industry has also led to a decline in traditional brick-and-mortar travel agencies as consumers shift towards the convenience and accessibility of online booking platforms. Therefore, having an attractive and competitive website in the hotel booking industry is more important than ever.",
  },
  {
    id: 2,
    text: "My chosen company is Opodo, and I will be focusing on the hotels side of their website (https://hotels.opodo.co.uk). Opodo is not one of the big names in the industry, but it is a booking website other websites redirect you to. I have chosen this website as a challenge to make it more attractive so that it would be a website people think of first when booking a hotel, rather than being redirected there.",
  },
  {
    id: 3,
    text: "Going through Opodo’s website, I identified several problems that would discourage customers from booking with them.",
  },
  {
    id: 4,
    text: "The homepage is uninspiring and is not eye-catching. The user can also click the close button on deals, which could encourage users to close it without looking at what it actually says, assuming it’s a pop up. Therefore, the deals are not properly advertised. Locations are suggested, but there’s no title, so it is unclear why those locations are being suggested.",
  },
  {
    id: 5,
    text: "The hotel page overall looks very boring and outdated. It looks too busy with information and could be cleaned up significantly.",
  },
  {
    id: 6,
    text: "The booking page is mostly straightforward, but they have split it into a two-page process rather than having the form be on one page, which could be bothersome to some users.",
  },
  {
    id: 7,
    text: "Overall, the website does not seem to stick to strict brand colours. There is also no real navigation bar, lacking a logo and a basket, so customers are unable to return to their booking choice if they have left the page.",
  }
]

export { CS2introText };

const CS2introImages = [
  {
    id: 1,
    img: CS2screenshotHome,
    alt: "Opodo Homepage Screenshot"
  },
  {
    id: 2,
    img: CS2screenshotHotel,
    alt: "Opodo Hotel Page Screenshot"
  },
  {
    id: 3,
    img: CS2screenshotBooking1,
    alt: "Opodo Booking Page Screenshot"
  },
  {
    id: 4,
    img: CS2screenshotBooking2,
    alt: "Opodo Booking Page Screenshot"
  }
]

export { CS2introImages };

// COMPETITOR ANALYSIS

const CS2competiorAnalysisText = [
  {
    id: 1,
    text: "A competitor analysis is a comparison between websites of the same and similar industries. It collects data about a competitor’s company and their products. The main reason competitor analyses are done is to gain insights from the collected data, minimise risk in design decisions, and make informed design decisions."
  },
  {
    id: 2,
    text: "Direct competitors are companies that have the same customers and offer the same products or service as your company. Opodo’s direct competitors are Vrbo, Super Travel, and Hotels.com, as they are all online hotel booking agencies."
  },
  {
    id: 3,
    text: "Indirect competitors are companies that offer something similar to your company. An indirect competitor of Opodo is Google Hotels, as Google Hotels is a metasearch engine."
  },
  {
    id: 4,
    text: "Here I have compared features of Opodo with its competitors and have noted strengths and weaknesses of the competitor’s online stores."
  },
]

export { CS2competiorAnalysisText };

const CS2competitorAnalysisImages = [
  {
    id: 1,
    img: CS2CompetitorAnalysis1,
    alt: "Vrbo Competitor Analysis"
  },
  {
    id: 2,
    img: CS2CompetitorAnalysis2,
    alt: "Super Travel Competitor Analysis"
  },
  {
    id: 3,
    img: CS2CompetitorAnalysis3,
    alt: "Hotels.com Competitor Analysis"
  },
  {
    id: 4,
    img: CS2CompetitorAnalysis4,
    alt: "Google Travel Competitor Analysis"
  },
  {
    id: 5,
    img: CS2CompetitorAnalysisGrid,
    alt: "Competitor Analysis Grid"
  }
]

export { CS2competitorAnalysisImages };

const CS2CAImagesHomepage = [
  {
    id: 1,
    img: CS2CAHome1,
    alt: "Vrbo Homepage"
  },
  {
    id: 2,
    img: CS2CAHome2,
    alt: "Super Travel Homepage"
  },
  {
    id: 3,
    img: CS2CAHome3,
    alt: "Hotels.com Homepage"
  },
  {
    id: 4,
    img: CS2CAHome4,
    alt: "Google Travel Homepage"
  },
]
export { CS2CAImagesHomepage };

const CS2CAImagesHotel = [
  {
    id: 1,
    img: CS2CAHotel1,
    alt: "Vrbo Hotel Page"
  },
  {
    id: 2,
    img: CS2CAHotel2,
    alt: "Hotels.com Hotel Page"
  },
  {
    id: 3,
    img: CS2CAHotel3,
    alt: "Google Travel Hotel Page"
  },
]
export { CS2CAImagesHotel };

const CS2CAImagesBooking = [
  {
    id: 1,
    img: CS2CABooking1,
    alt: "Vrbo Booking Page"
  },
  {
    id: 2,
    img: CS2CABooking2,
    alt: "Hotels.com Booking Page"
  },
]
export { CS2CAImagesBooking };

// USER RESEARCH

const CS2userResearchText = [
  {
    id: 1,
    text: "Red routes are the main paths users will take on their journey through a website. In this activity, participants were asked what they felt were the most important features of a hotel booking website were. The options were search, navigation, deals, suggested destinations, reviews, and account."
  },
  {
    id: 2,
    text: "Search was placed by all participants as being used by all users, both always used and frequently used, and so I can conclude that for a hotel booking website this is the most key feature. Deals, Navigation, Account, and Reviews were also placed as being important features, being used by either all or many users."
  },
  {
    id: 3,
    text: "From this I can conclude that Search, Account, Navigation, Deals and Reviews are the most important features to include in a hotel booking website."
  }
]

export { CS2userResearchText };

const CS2userResearchImages = [
  {
    id: 1,
    img: CS2UserResearchQuestionnaire,
    alt: "User Research Questionnaire"
  },
  {
    id: 2,
    img: CS2RedRouteAnalysis,
    alt: "Red Route Analysis"
  }
]

export { CS2userResearchImages };


// IDEATION - CRAZY EIGHTS

const CS2ideationText = [
  {
    id: 1,
    text: "I have chosen the ideation technique of Crazy Eights. Crazy Eights is a design sprint, where you are given one minute to sketch out one design, ending with eight designs in eight minutes. From analysing the results of my business research and user research, I focused on the homepage and the hotel page, as those were pages that needed the most improvement. This ideation will benefit my design process as it has allowed me to visually consider different approaches for the homepage and hotel page."
  },
  {
    id: 2,
    text: "In the home page ideation, I added a hero banner and a navigation bar and played with the placements of the search bar. The section below the search bar include popular destinations in all three versions, the second version being a carousel. In the first version, it is followed by icons detailing reasons to choose Opodo. In the second version, this is a review carousel. In the third version, this section is cards displaying deals."
  },
  {
    id: 3,
    text: "In the hotel page ideation, the search bar has been moved to the top underneath the navigation bar instead of taking up room on the page. Version 1 and 3 have a collage of photos as the hero banner, with a button to view more, whilst Version 2 has just a normal hero banner with the view more button. I also added a navigation below this section to easily navigate the rest of the page. In version 1 and 2, the section below are cards displaying the available rooms, whilst in version 3 this section is the about the hotel with a map."
  },
  {
    id: 4,
    text: "I made two versions of the booking forms. In the top version, the screen is split evenly between the booking form on the left and the booking details on the right. The details include an image of the hotel, information, price and purchase button. In the bottom version, there is less space given to the booking details, it now only taking up one third of the page with two-thirds given to the booking form to give it more importance."
  },
]

export { CS2ideationText };

const CS2ideationImages = [
  {
    id: 1,
    img: CS2Ideation,
    alt: "Crazy Eights"
  },
  {
    id: 2,
    img: CS2CrazyEights1,
    alt: "Crazy Eights Homepage"
  },
  {
    id: 3,
    img: CS2CrazyEights2,
    alt: "Crazy Eights Hotel Page"
  },
  {
    id: 4,
    img: CS2CrazyEights3,
    alt: "Crazy Eights Booking Form"
  },
]

export { CS2ideationImages };


// LOW FIDELITY WIREFRAMES

const CS2lowFidelityWireframes = [
  {
    id: 1,
    img: CS2lowWireframe1,
    alt: "Low Fidelity Wireframe Homepage"
  },
  {
    id: 2,
    img: CS2lowWireframe2,
    alt: "Low Fidelity Wireframe Hotel Page"
  },
  {
    id: 3,
    img: CS2lowWireframe3,
    alt: "Low Fidelity Wireframe Booking Page"
  }
]

export { CS2lowFidelityWireframes };


// MID FIDELITY WIREFRAMES

const CS2midFidelityWireframes = [
  {
    id: 1,
    img: CS2midWireframe1,
    alt: "Mid Fidelity Wireframe Homepage"
  },
  {
    id: 2,
    img: CS2midWireframe2,
    alt: "Mid Fidelity Wireframe Hotel Page"
  },
  {
    id: 3,
    img: CS2midWireframe3,
    alt: "Mid Fidelity Wireframe Booking Page"
  }
]

export { CS2midFidelityWireframes };


// HIGH FIDELITY WIREFRAMES

const CS2highFidelityWireframes = [
  {
    id: 1,
    img: CS2highWireframe1,
    alt: "High Fidelity Wireframe Homepage"
  },
  {
    id: 2,
    img: CS2highWireframe2,
    alt: "High Fidelity Wireframe Hotel Page"
  },
  {
    id: 3,
    img: CS2highWireframe3,
    alt: "High Fidelity Wireframe Booking Page"
  }
]

export { CS2highFidelityWireframes };


// UI PHASE

const CS2uiPhaseImages = [
  {
    id: 1,
    img: CS2styleGuide,
    alt: "Style Guide"
  },
  {
    id: 2,
    img: CS2typography,
    alt: "Typography"
  },
  {
    id: 3,
    img: CS2alignment,
    alt: "Alignment and Grid"
  },
  {
    id: 4,
    img: CS2accessibility,
    alt: "Accessibility"
  },
]

export { CS2uiPhaseImages }


// CONCLUSION

const CS2conclusionText = [
  {
    id: 1,
    text: "The research phase of my case study was particularly useful, as I researched competitor websites and saw how they displayed their homepage, forms, and hotel information. The user research process also went well as I gained a lot of valuable insights into the user experience, which allowed me to identify pain points and areas for improvement, as well as see what the user deemed as important features."
  },
  {
    id: 2,
    text: "The ideation and wireframing phases were also useful, as I was able to come up with creative new ideas to enhance the website's functionality and user experience."
  },
  {
    id: 3,
    text: "Overall, I believe I was able to meet my objective of improving the Opodo website thanks to the design steps I took. To see if my design truly improved the website, I would do user testing and gather further feedback to help improve the new design."
  },
]

export { CS2conclusionText };