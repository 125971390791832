// Opodo Case Study

import '../sass/main.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFigma } from '@fortawesome/free-brands-svg-icons';

// Content
import {
  CS2introText, CS2introImages,
  CS2competiorAnalysisText, CS2competitorAnalysisImages,
  CS2CAImagesHomepage, CS2CAImagesHotel, CS2CAImagesBooking,
  CS2userResearchImages, CS2userResearchText,
  CS2ideationText, CS2ideationImages,
  CS2lowFidelityWireframes, CS2midFidelityWireframes, CS2highFidelityWireframes,
  CS2uiPhaseImages, CS2conclusionText
} from './CaseStudy2Content';

const CaseStudy2 = () => {
  const [showMoreIntro, setShowMoreIntro] = useState(false);
  const [showMoreCA, setShowMoreCA] = useState(false);
  const [showMoreIdeation, setShowMoreIdeation] = useState(false);
  const [showMoreLowWireframes, setShowMoreLowWireframes] = useState(false);
  const [showMoreMidWireframes, setShowMoreMidWireframes] = useState(false);
  const [showMoreHighWireframes, setShowMoreHighWireframes] = useState(false);
  return (
    <section className="page-container project-page">
      <h1>Opodo</h1>

      <article className="project-details">
        <div>
          <h5>Client</h5>
          <p>Love Circular</p>
        </div>
        <div>
          <h5>Role</h5>
          <p>UX/UI Designer</p>
        </div>
        <div>
          <h5>Date</h5>
          <p>March 2023</p>
        </div>
        <div>
          <h5>Link</h5>
          <Link to="https://www.figma.com/proto/CqPY5XAaSt223mBeNtc1fM/Case-Study-2?type=design&node-id=66-2&scaling=min-zoom&page-id=36%3A2&starting-point-node-id=66%3A2" target="blank"><FontAwesomeIcon icon={faFigma} /></Link>
        </div>
      </article>

      {/* Intro */}
      <article>
        <h2>Introduction</h2>
        <p>{CS2introText[0].text}</p>
        <p>{CS2introText[1].text}</p>

        <h3 className="centre-elements">Problems</h3>
        <p>{CS2introText[2].text}</p>
        <p>{CS2introText[3].text}</p>
        <p>{CS2introText[4].text}</p>
        <p>{CS2introText[5].text}</p>
        <p>{CS2introText[6].text}</p>
      </article>

      <article className="centre-elements">
        <h3>Website Screenshots</h3>
        <button onClick={() => setShowMoreIntro(!showMoreIntro)}>
          {showMoreIntro ? "Hide Images" : "Show Images"}
        </button>
        {showMoreIntro &&
          <div className="centre-elements">
            <div className="image-grid">
              <img className="small-image" src={CS2introImages[0].img} alt={CS2introImages[0].alt} />
              <img className="small-image" src={CS2introImages[1].img} alt={CS2introImages[1].alt} />
              <img className="small-image" src={CS2introImages[2].img} alt={CS2introImages[2].alt} />
              <img className="small-image" src={CS2introImages[3].img} alt={CS2introImages[3].alt} />
            </div>
            <button onClick={() => setShowMoreIntro(!showMoreIntro)}>
              {showMoreIntro ? "Hide Images" : "Show Images"}
            </button>
          </div>
        }
      </article>

      {/* Competitor Analysis */}
      <article >
        <h2>Competitor Analysis</h2>
        <p>{CS2competiorAnalysisText[0].text}</p>
        <p>{CS2competiorAnalysisText[1].text}</p>
        <p>{CS2competiorAnalysisText[2].text}</p>
        <p>{CS2competiorAnalysisText[3].text}</p>
        <div className="centre-elements">
          <img className="extra-large-image" src={CS2competitorAnalysisImages[4].img} alt={CS2competitorAnalysisImages[4].alt} />

          <button onClick={() => setShowMoreCA(!showMoreCA)}>
            {showMoreCA ? "Hide Images" : "Show Images"}
          </button>
          {showMoreCA &&
            <div className="centre-elements">
              <h3>Homepages</h3>
              <div className="ca-grid">
                <img className="ca-grid-image" src={CS2CAImagesHomepage[0].img} alt={CS2CAImagesHomepage[0].alt} />
                <img className="ca-grid-image" src={CS2CAImagesHomepage[1].img} alt={CS2CAImagesHomepage[1].alt} />
                <img className="ca-grid-image" src={CS2CAImagesHomepage[2].img} alt={CS2CAImagesHomepage[2].alt} />
                <img className="ca-grid-image" src={CS2CAImagesHomepage[3].img} alt={CS2CAImagesHomepage[3].alt} />
              </div>

              <h3>Hotel Page</h3>
              <div className="wireframe-grid">
                <img className="wireframe-grid-image" src={CS2CAImagesHotel[0].img} alt={CS2CAImagesHotel[0].alt} />
                <img className="wireframe-grid-image" src={CS2CAImagesHotel[1].img} alt={CS2CAImagesHotel[1].alt} />
                <img className="wireframe-grid-image" src={CS2CAImagesHotel[2].img} alt={CS2CAImagesHotel[2].alt} />
              </div>

              <h3>Booking Page</h3>
              <div className="image-grid">
                <img className="small-image" src={CS2CAImagesBooking[0].img} alt={CS2CAImagesBooking[0].alt} />
                <img className="small-image" src={CS2CAImagesBooking[1].img} alt={CS2CAImagesBooking[1].alt} />
              </div>
              <button onClick={() => setShowMoreCA(!showMoreCA)}>
                {showMoreCA ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>
      </article>

      {/* User Research */}
      <article>
        <h2>User Research</h2>
        <img className="extra-large-image" src={CS2userResearchImages[0].img} alt={CS2userResearchImages[0].alt} />
        <div>
          <h3 className="centre-elements">Red Route Analysis</h3>
          <p>{CS2userResearchText[0].text}</p>
          <p>{CS2userResearchText[1].text}</p>
          <p>{CS2userResearchText[2].text}</p>
          <div className="centre-elements">
            <img className="extra-large-image" src={CS2userResearchImages[1].img} alt={CS2userResearchImages[1].alt} />
          </div>
        </div>
      </article>

      {/* Ideation */}
      <article>
        <h2>Ideation</h2>
        <h3 className="centre-elements">Crazy Eights</h3>
        <p>{CS2ideationText[0].text}</p>

        <div className="centre-elements">
          <img className="large-image" src={CS2ideationImages[0].img} alt={CS2ideationImages[0].alt} />
          <button onClick={() => setShowMoreIdeation(!showMoreIdeation)}>
            {showMoreIdeation ? "Read Less" : "Read More"}
          </button>
          {showMoreIdeation &&
            <div>
              <div className="centre-elements">
                <img className="medium-image" src={CS2ideationImages[1].img} alt={CS2ideationImages[1].alt} />
                <p>{CS2ideationText[1].text}</p>
                <img className="medium-image" src={CS2ideationImages[2].img} alt={CS2ideationImages[2].alt} />
                <p>{CS2ideationText[2].text}</p>
                <img className="mobile-image" src={CS2ideationImages[3].img} alt={CS2ideationImages[3].alt} />
                <p>{CS2ideationText[3].text}</p>
              </div>
              <div className="centre-elements">
                <button onClick={() => setShowMoreIdeation(!showMoreIdeation)}>
                  {showMoreIdeation ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>}
        </div>
      </article>

      {/* Wireframes */}
      <article>
        <h2>Wireframes</h2>

        {/* Low-Fidelity Wireframes */}
        <div className="centre-elements">
          <h3>Low-Fidelity Wireframes</h3>
          <img className="large-image" src={CS2lowFidelityWireframes[0].img} alt={CS2lowFidelityWireframes[0].alt} />
          <button onClick={() => setShowMoreLowWireframes(!showMoreLowWireframes)}>
            {showMoreLowWireframes ? "Hide Images" : "Show Images"}
          </button>
          {showMoreLowWireframes &&
            <div className="centre-elements">
              <div className="image-grid">
                <img className="large-image" src={CS2lowFidelityWireframes[1].img} alt={CS2lowFidelityWireframes[1].img} />
                <img className="large-image" src={CS2lowFidelityWireframes[2].img} alt={CS2lowFidelityWireframes[2].img} />
              </div>
              <button onClick={() => setShowMoreLowWireframes(!showMoreLowWireframes)}>
                {showMoreLowWireframes ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>

        {/* Mid-Fidelity Wireframes */}
        <div className="centre-elements">
          <h3>Mid-Fidelity Wireframes</h3>
          <img className="small-image" src={CS2midFidelityWireframes[0].img} alt={CS2midFidelityWireframes[0].alt} />
          <button onClick={() => setShowMoreMidWireframes(!showMoreMidWireframes)}>
            {showMoreMidWireframes ? "Hide Images" : "Show Images"}
          </button>
          {showMoreMidWireframes &&
            <div className="centre-elements">
              <div className="image-grid">
                <img className="small-image" src={CS2midFidelityWireframes[1].img} alt={CS2midFidelityWireframes[1].img} />
                <img className="small-image" src={CS2midFidelityWireframes[2].img} alt={CS2midFidelityWireframes[2].img} />
              </div>
              <button onClick={() => setShowMoreMidWireframes(!showMoreMidWireframes)}>
                {showMoreMidWireframes ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>

        {/* High-Fidelity Wireframes */}
        <div className="centre-elements">
          <h3>High-Fidelity Wireframes</h3>
          <div className="image-grid">
            <div className="centre-elements">
              <h4>Before - Original Design</h4>
              <img className="small-image" src={CS2introImages[0].img} alt={CS2introImages[0].alt} />
            </div>
            <div className="centre-elements">
              <h4>After - My Design</h4>
              <img className="small-image" src={CS2highFidelityWireframes[0].img} alt={CS2highFidelityWireframes[0].alt} />
            </div>
          </div>
          <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
            {showMoreHighWireframes ? "Hide Images" : "Show Images"}
          </button>
          {showMoreHighWireframes &&
            <div className="centre-elements">
              <div className="image-grid">
                <img className="small-image" src={CS2highFidelityWireframes[1].img} alt={CS2highFidelityWireframes[1].img} />
                <img className="small-image" src={CS2highFidelityWireframes[2].img} alt={CS2highFidelityWireframes[2].img} />
              </div>
              <button onClick={() => setShowMoreHighWireframes(!showMoreHighWireframes)}>
                {showMoreHighWireframes ? "Hide Images" : "Show Images"}
              </button>
            </div>
          }
        </div>
      </article>

      {/* UI Phase */}
      <article>
        <h2>UI Phase</h2>
        <div className="centre-elements">
          <img className="extra-large-image" src={CS2uiPhaseImages[0].img} alt={CS2uiPhaseImages[0].alt} />
          <img className="extra-large-image" src={CS2uiPhaseImages[1].img} alt={CS2uiPhaseImages[1].alt} />
          <img className="extra-large-image" src={CS2uiPhaseImages[2].img} alt={CS2uiPhaseImages[2].alt} />
          <img className="extra-large-image" src={CS2uiPhaseImages[3].img} alt={CS2uiPhaseImages[3].alt} />
        </div>
      </article>

      {/* Conclusion */}
      <article>
        <h2>Conclusion</h2>
        <p>{CS2conclusionText[0].text}</p>
        <p>{CS2conclusionText[1].text}</p>
        <p>{CS2conclusionText[2].text}</p>
      </article>
    </section >
  )
};

export default CaseStudy2;