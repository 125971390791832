// EyesOnMe Content

// Image Imports

import UIP1CAInstagram from '../assets/ui-project-1/ca-instagram.png'
import UIP1CATwitter from '../assets/ui-project-1/ca-twitter.png'
import UIP1CAArtfol from '../assets/ui-project-1/ca-artfol.png'

import UIP1lowWireframes from '../assets/ui-project-1/wireframes-low-annotated.png'

import UIP1highWireframeHome from '../assets/ui-project-1/wireframe-high-homepage.png'
import UIP1highWireframeFriendProfile from '../assets/ui-project-1/wireframe-high-friend-profile.png'
import UIP1highWireframeFollowing from '../assets/ui-project-1/wireframe-high-friend-following.png'
import UIP1highWireframeSettings from '../assets/ui-project-1/wireframe-high-settings.png'
import UIP1highWireframeProfile from '../assets/ui-project-1/wireframe-high-profile.png'
import UIP1highWireframeProfileTexts from '../assets/ui-project-1/wireframe-high-profile-texts.png'
import UIP1highWireframeLogIn from '../assets/ui-project-1/wireframe-high-login.png'
import UIP1highWireframeSignUp from '../assets/ui-project-1/wireframe-high-signup.png'
import UIP1highWireframeMessage1 from '../assets/ui-project-1/wireframe-high-message1.png'
import UIP1highWireframeMessage2 from '../assets/ui-project-1/wireframe-high-message2.png'

import UIP1styleGuide from '../assets/ui-project-1/ui-style-guide.png'
import UIP1603010Rule from '../assets/ui-project-1/ui-60-30-10.png'

// INTRODUCTION

const UIP1introText = [
  {
    id: 1,
    text: "The social media industry has expanded and evolved over the past decade. Whilst social media platforms were originally designed to connect people with friends and family, the industry has increasingly shifted away from this, promoting curated, aspirational content rather than authentic, personal connections. ",
  },
  {
    id: 2,
    text: "As a result, many social media platforms have shifted their algorithms and features to prioritize popular accounts and content over personal connections. This has led to a decline in the importance of social media for maintaining and building personal relationships.",
  },
  {
    id: 3,
    text: "Additionally, with the rise of influencer culture, many users have sought to build a false persona or brand that is detached from their true identity. This has led to an increasing focus on creating a 'perfect' online image, rather than sharing personal and authentic content with friends and family.",
  },
  {
    id: 4,
    text: "In this project, I aimed to create a social media platform that aims to encourage connections between individuals, instead of simply following them. ",
  },
]

export { UIP1introText };


// COMPETITOR ANALYSIS

const UIP1competiorAnalysisText = [
  {
    id: 1,
    text: "For direct competitors to my app, I looked at Instagram and Twitter, two of the leading social media platforms where individuals are connected with people they know. For indirect, I looked at Artfol, a new app which focuses on sharing art. I picked it as the app has a focus on building community."
  },
]

export { UIP1competiorAnalysisText };

const UIP1competitorAnalysisImages = [
  {
    id: 1,
    img: UIP1CAInstagram,
    alt: "Instagram Competitor Analysis"
  },
  {
    id: 2,
    img: UIP1CATwitter,
    alt: "Twitter Competitor Analysis"
  },
  {
    id: 3,
    img: UIP1CAArtfol,
    alt: "Artfol Competitor Analysis"
  },
]

export { UIP1competitorAnalysisImages };


// LOW FIDELITY WIREFRAMES

const UIP1lowFidelityWireframes = [
  {
    id: 1,
    img: UIP1lowWireframes,
    alt: "Low Fidelity Wireframes"
  },
]

export { UIP1lowFidelityWireframes };

// HIGH FIDELITY WIREFRAMES

const UIP1highFidelityWireframes = [
  {
    id: 1,
    img: UIP1highWireframeHome,
    alt: "High Fidelity Wireframe Homepage"
  },
  {
    id: 2,
    img: UIP1highWireframeFriendProfile,
    alt: "High Fidelity Wireframe Friend Profile"
  },
  {
    id: 3,
    img: UIP1highWireframeFollowing,
    alt: "High Fidelity Wireframe Following Page"
  },
  {
    id: 4,
    img: UIP1highWireframeSettings,
    alt: "High Fidelity Wireframe Settings Page"
  },
  {
    id: 5,
    img: UIP1highWireframeProfile,
    alt: "High Fidelity Wireframe User Profile"
  },
  {
    id: 6,
    img: UIP1highWireframeProfileTexts,
    alt: "High Fidelity Wireframe User Profile 2"
  },
  {
    id: 7,
    img: UIP1highWireframeLogIn,
    alt: "High Fidelity Wireframe Log In Page"
  },
  {
    id: 8,
    img: UIP1highWireframeSignUp,
    alt: "High Fidelity Wireframe Sign Up Page"
  },
  {
    id: 9,
    img: UIP1highWireframeMessage1,
    alt: "High Fidelity Wireframe Message Page"
  },
  {
    id: 10,
    img: UIP1highWireframeMessage2,
    alt: "High Fidelity Wireframe Message Page 2"
  },
]

export { UIP1highFidelityWireframes };


// UI PHASE

const UIP1uiPhaseImages = [
  {
    id: 1,
    img: UIP1styleGuide,
    alt: "Style Guide"
  },
  {
    id: 2,
    img: UIP1603010Rule,
    alt: "60-30-10 Rule"
  },
]

export { UIP1uiPhaseImages }

// CONCLUSION 

const UIP1conclusionText = [
  {
    id: 1,
    text: "Researching competitors allowed me to take aspects from their designs that I believed would help encourage connections between individuals when using the app. Displaying not just usernames, but display names too helps to increase the feeling of familiarity with the user. "
  },
  {
    id: 2,
    text: "Additionally, I included a share post function, where a user can share a friend’s post and write their own comment on it. I also included a text post function, which users can also like, share and respond to, to help with the familiarity. "
  },
  {
    id: 3,
    text: "Overall I believe I was able to create a social media app design that users can connect with their friends with whilst using it."
  }
]

export { UIP1conclusionText };